import {useState} from "react";
import {Actions, Menu, MobileMenu, MobileSearch} from "./components";
import {useContentLoader, useScrollingUp} from "hooks";
import {useSelector} from "react-redux";
import {Cart} from "components";
import { Logo } from "ui";

const Header = (props) => {
  useContentLoader();
  const {className} = props;
  const scrolled = useScrollingUp();

  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  const menus = useSelector(state => state.globalContent.menus);
  const locale = useSelector(state => state.i18n.locale);

  const menuList = menus?.['header']?.[locale ?? 'et']?.menuList;

  return (
    <div className="relative header__wrapper">
      <header className={`header-section${className ? " " + className : ""}${scrolled ? ' header-section--sticky' : ''}`}>
        <div className="container container-big">
          <div className="header">
            <Logo/>
            <Menu menuList={menuList}/>
            <Actions onMobileMenuToggle={() => setMobileMenuIsOpen(!mobileMenuIsOpen)} />
            <MobileMenu menuList={menuList}  />
          </div>
        </div>

        {mobileMenuIsOpen && <MobileSearch />}
      </header>
      <Cart/>
    </div>
  );
};

export default Header;

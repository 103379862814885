export default function IconPhone () {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5054 12.5054C15.5054 13.0987 15.3294 13.6787 14.9998 14.1721C14.6701 14.6654 14.2016 15.0499 13.6534 15.277C13.1052 15.5041 12.502 15.5635 11.9201 15.4477C11.3382 15.332 10.8036 15.0462 10.3841 14.6267C9.96449 14.2071 9.67877 13.6726 9.56301 13.0906C9.44726 12.5087 9.50667 11.9055 9.73373 11.3573C9.96079 10.8091 10.3453 10.3406 10.8387 10.011C11.332 9.68132 11.912 9.50537 12.5054 9.50537C13.3003 9.50784 14.0619 9.82471 14.624 10.3868C15.186 10.9489 15.5029 11.7105 15.5054 12.5054ZM21.8804 8.38037V16.6304C21.8804 18.0228 21.3272 19.3581 20.3427 20.3427C19.3581 21.3272 18.0228 21.8804 16.6304 21.8804H8.38037C6.98798 21.8804 5.65263 21.3272 4.66806 20.3427C3.68349 19.3581 3.13037 18.0228 3.13037 16.6304V8.38037C3.13037 6.98798 3.68349 5.65263 4.66806 4.66806C5.65263 3.68349 6.98798 3.13037 8.38037 3.13037H16.6304C18.0228 3.13037 19.3581 3.68349 20.3427 4.66806C21.3272 5.65263 21.8804 6.98798 21.8804 8.38037ZM17.0054 12.5054C17.0054 11.6154 16.7415 10.7453 16.247 10.0053C15.7525 9.26528 15.0497 8.68851 14.2274 8.34791C13.4052 8.00732 12.5004 7.9182 11.6275 8.09184C10.7545 8.26547 9.95273 8.69405 9.32339 9.32339C8.69405 9.95273 8.26547 10.7545 8.09184 11.6275C7.9182 12.5004 8.00732 13.4052 8.34791 14.2274C8.68851 15.0497 9.26528 15.7525 10.0053 16.247C10.7453 16.7415 11.6154 17.0054 12.5054 17.0054C13.6988 17.0054 14.8434 16.5313 15.6874 15.6874C16.5313 14.8434 17.0054 13.6988 17.0054 12.5054ZM18.5054 7.63037C18.5054 7.40787 18.4394 7.19036 18.3158 7.00535C18.1922 6.82035 18.0165 6.67616 17.8109 6.59101C17.6053 6.50586 17.3791 6.48358 17.1609 6.52699C16.9427 6.5704 16.7422 6.67754 16.5849 6.83488C16.4275 6.99221 16.3204 7.19267 16.277 7.41089C16.2336 7.62912 16.2559 7.85532 16.341 8.06089C16.4262 8.26646 16.5703 8.44216 16.7554 8.56577C16.9404 8.68939 17.1579 8.75537 17.3804 8.75537C17.6787 8.75537 17.9649 8.63684 18.1759 8.42587C18.3868 8.21489 18.5054 7.92874 18.5054 7.63037Z"
        fill="currentColor"
      />
    </svg>
  )
};

export default function IconEmail () {
  return (
    <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.7593 8.46048L22.9373 14.8212V0.970062L14.7593 8.46048Z" fill="currentColor" />
      <path d="M21.7708 15.7299L13.691 9.44285L11.4684 11.4812L9.23357 9.44285L1.16602 15.7299H21.7708Z" fill="currentColor" />
      <path d="M1.05566 0L11.4686 9.54108L21.8692 0H1.05566Z" fill="currentColor" />
      <path d="M8.16579 8.46048L0 0.970062V14.8212L8.16579 8.46048Z" fill="currentColor" />
    </svg>
  )
};

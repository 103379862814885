import { motion } from "framer-motion";
import { useTranslatableContent } from "hooks";

const PageHead = (props) => {
  const { className, title, description, subtitle, onClick, textAlign = null, underline = true} = props;
  const classes = `page-head-section${className ? " " + className : ""}`;

  const maybeTranslate = useTranslatableContent();

  const titleVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const descriptionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.2 } },
  };

  const styles = {
    ...(textAlign ? { textAlign } : {}),
  };

  return (
    <section className={classes}>
      <div className="container">
        <div className={"page-head" + (underline ? ' page-head__underline' : '')} style={styles}>
          {subtitle && (
            <div className="page-head__subtitle" onClick={onClick}>
              {subtitle.icon} {subtitle.text}
            </div>
          )}
          {maybeTranslate(title) ? (
            <motion.h1
              className="page-head__title"
              variants={titleVariants}
              initial="hidden"
              animate="visible"
            >
              {maybeTranslate(title)}
            </motion.h1>
          ) : null}
          {description && (
            <motion.p
              className="page-head__description"
              variants={descriptionVariants}
              initial="hidden"
              animate="visible"
            >
              {description}
            </motion.p>
          )}
        </div>
      </div>
    </section>
  );
};

export default PageHead;

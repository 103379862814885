export default function IconTwitter () {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 5.76983C20.3262 6.07632 19.6494 6.30769 18.8994 6.38582C19.6494 5.92308 20.25 5.15385 20.5488 4.30649C19.7988 4.76923 19.0488 5.07572 18.2256 5.23197C17.4756 4.46274 16.5 4 15.4512 4C13.4238 4 11.7744 5.69171 11.7744 7.76803C11.7744 8.07752 11.7744 8.38401 11.8506 8.61538C8.77441 8.46214 6.07617 6.9988 4.27441 4.69111C3.89941 5.23197 3.75 5.92308 3.75 6.61418C3.75 7.92428 4.42383 9.07813 5.39941 9.76923C4.79883 9.69111 4.20117 9.53786 3.75 9.30649C3.75 9.30649 3.75 9.30649 3.75 9.38462C3.75 11.2296 5.02441 12.768 6.67383 13.0775C6.375 13.1526 6.07617 13.2308 5.70117 13.2308C5.47559 13.2308 5.25 13.2308 5.02441 13.1526C5.47559 14.6911 6.82617 15.7698 8.47559 15.7698C7.20117 16.7704 5.625 17.3834 3.89941 17.3834C3.60059 17.3834 3.29883 17.3834 3 17.3083C4.64941 18.384 6.60059 19 8.625 19C15.4512 19 19.125 13.2308 19.125 8.23077C19.125 8.07752 19.125 7.92428 19.125 7.76803C19.875 7.23017 20.4756 6.53906 21 5.76983Z"
        fill="currentColor"
      />
    </svg>
  )
};

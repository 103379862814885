import Icon from 'components/Icons/Icon';
import { useTranslatableContent } from 'hooks';

export default function Socials ({
  className = '',
  list = [],
}) {
  const maybeTranslate = useTranslatableContent();

  if (!list) {
    return null;
  }

  return (
    <div className={`socials ${className}`}>
      {Object.entries(list).map(([icon, item]) =>
        typeof item === 'string' ? (
          <a key={item} href={item} target="_blank" rel="noreferrer">
            <Icon icon={icon} />
          </a>
        ) : (item?.link ? (
          <a key={item?.name} href={maybeTranslate(item?.link)} target="_blank" rel="noreferrer">
            <Icon icon={item?.icon} />
          </a>
        ) : null)
      )}
    </div>
  );
};

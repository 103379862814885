import React from 'react';
import IconArrow from './IconArrow';
import IconEmail from './IconEmail';
import IconFacebook from './IconFacebook';
import IconInstagram from './IconInstagram';
import IconPhone from './IconPhone';
import IconTwitter from './IconTwitter';
import IconYoutube from './IconYoutube';

const icons = {
  arrow: IconArrow,
  email: IconEmail,
  facebook: IconFacebook,
  instagram: IconInstagram,
  phone: IconPhone,
  twitter: IconTwitter,
  youtube: IconYoutube,
}

export default function Icon ({ icon }) {
  if (!icon) {
    return null;
  }

  icon = icon.toLowerCase();

  if (typeof icons[icon] !== 'undefined') {
    return React.createElement(icons[icon]);
  }

  return React.createElement(
    () => <span>The icon {icon} not found!</span>,
  );
}

import Favourites from 'components/Favourites';
import { LanguageSwitcher, Burger, CartButton, Search } from '..';

const Actions = (props) => {
  const { className } = props;

  const classes = `actions${className ? ' ' + className : ''}`;

  return (
    <div className={classes}>
      <Search onMobileMenuToggle={props.onMobileMenuToggle} />
      <Favourites />
      <CartButton />
      <LanguageSwitcher />
      <Burger />
    </div>
  );
};

export default Actions;

export default function IconArrow() {
  return (
    <svg
      width="23"
      height="12"
      viewBox="0 0 23 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1331 0.730843L22.6331 5.22877L22.6326 6.77736L17.1326 11.2696L15.8674 9.72056L19.1948 7.00287H0V5.00287H19.1976L15.8669 2.27904L17.1331 0.730843Z"
        fill="currentColor"
      />
    </svg>
  );
};

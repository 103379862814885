export default function IconPhone () {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 4C2.172 4 2 4.90805 2 12C2 19.0919 2.172 20 12 20C21.828 20 22 19.0919 22 12C22 4.90805 21.828 4 12 4ZM15.205 12.347L10.715 14.5247C10.322 14.7138 10 14.5018 10 14.0509V9.94909C10 9.49922 10.322 9.28623 10.715 9.47533L15.205 11.653C15.598 11.8442 15.598 12.1558 15.205 12.347Z"
        fill="currentColor"
      />
    </svg>
  )
};
